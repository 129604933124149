var getOffice = function (data) {
    var offices = [];
    data === null || data === void 0 ? void 0 : data.forEach(function (elt) {
        return elt.offices.forEach(function (element) {
            if (!offices.includes(element.office.name)) {
                offices.push(element.office.name);
            }
        });
    });
    return offices;
};
var getStatClient = function (data) {
    return data.map(function (elt) { return elt.statClient.name; });
};
export var getChartParams = function (data) {
    var offices = getOffice(data);
    var statClient = getStatClient(data);
    var dataFiled = statClient.map(function (stat) {
        var _a;
        var relativeOffice = (_a = data.find(function (elt) { return elt.statClient.name === stat; })) === null || _a === void 0 ? void 0 : _a.offices;
        var dataResult = offices.map(function (elt) {
            var office = relativeOffice === null || relativeOffice === void 0 ? void 0 : relativeOffice.find(function (element) { return element.office.name === elt; });
            return (office === null || office === void 0 ? void 0 : office.nbStatClientResponses) || 0;
        });
        return {
            name: stat,
            data: dataResult,
        };
    });
    var _a = checkNoCategorySerie(dataFiled, offices), categories = _a.categories, series = _a.series;
    return {
        categories: categories,
        series: series,
    };
};
export var getChartParamsStatClientResponseByMonth = function (data) {
    var statClient = [];
    data === null || data === void 0 ? void 0 : data.forEach(function (elt) {
        var _a;
        (_a = elt.stats) === null || _a === void 0 ? void 0 : _a.forEach(function (stat) {
            if (!statClient.includes(stat.statClient.name))
                statClient.push(stat.statClient.name);
        });
    });
    var categories = data.map(function (elt) { var _a; return (_a = elt === null || elt === void 0 ? void 0 : elt.date) === null || _a === void 0 ? void 0 : _a.toString(); });
    var series = statClient.map(function (category) { return ({
        name: category,
        data: getDataCategory(data, category),
    }); });
    var _a = checkNoCategorySerie(series, categories), resSeries = _a.series, resCategories = _a.categories;
    return { categories: resCategories, series: resSeries };
};
var getDataCategory = function (data, category) {
    return data.map(function (elt) {
        var res = elt.stats.find(function (stat) { return stat.statClient.name === category; });
        return (res === null || res === void 0 ? void 0 : res.totalNumber) || 0;
    });
};
var getPercentage = function (val, total) { return (val * 100) / total; };
export var getChartStatsClientResponse = function (data) {
    var total = data.reduce(function (accumulator, currentValue) { return accumulator + currentValue.nbStatClientResponses; }, 0);
    var categories = data.map(function (elt) { return getPercentage(elt === null || elt === void 0 ? void 0 : elt.nbStatClientResponses, total) || 0; });
    var series = data === null || data === void 0 ? void 0 : data.map(function (el) {
        var _a;
        return ({
            label: (_a = el === null || el === void 0 ? void 0 : el.office) === null || _a === void 0 ? void 0 : _a.name,
            value: getPercentage(el === null || el === void 0 ? void 0 : el.nbStatClientResponses, total) || 0,
        });
    });
    return { categories: categories, series: series };
};
var distractData = function (data, category, offices) {
    var categoryFind = data.find(function (elt) { return elt.response === category; });
    return offices.map(function (office) { var _a; return ((_a = categoryFind === null || categoryFind === void 0 ? void 0 : categoryFind.offices.find(function (elt) { return elt.office.name === office; })) === null || _a === void 0 ? void 0 : _a.count) || 0; });
};
export var getChartKpiStatsResponse = function (data) {
    if (!data)
        return {
            categories: [],
            series: [],
        };
    var offices = getOffice(data);
    data.map(function (elt) {
        return elt.offices.forEach(function (ele) {
            return elt.offices.forEach(function (office) {
                if (!offices.includes(office.office.name))
                    offices.push(office.office.name);
            });
        });
    });
    var categoriesData = data.map(function (elt) { return elt.response; });
    var seriesData = categoriesData.map(function (category) { return ({
        name: category,
        data: distractData(data, category, offices),
    }); });
    var _a = checkNoCategorySerie(seriesData, offices), series = _a.series, categories = _a.categories;
    return {
        categories: categories,
        series: series,
    };
};
export var getChartKpiStatsResponseByRange = function (data) {
    var categoriesData = data.map(function (elt) { return elt.date; });
    var statsResponse = [];
    var obj = {};
    data.forEach(function (elt) {
        var subObj = {};
        elt.stats.forEach(function (stat) {
            subObj[stat.response] = stat.nbResponses;
        });
        obj[elt.date] = subObj;
    });
    data.forEach(function (period) {
        return period.stats.forEach(function (stat) {
            if (!statsResponse.includes(stat.response))
                statsResponse.push(stat.response);
        });
    });
    var seriesInput = statsResponse.map(function (stat) { return ({
        name: stat,
        data: categoriesData === null || categoriesData === void 0 ? void 0 : categoriesData.map(function (category) { return obj[category][stat] || 0; }),
    }); });
    var _a = checkNoCategorySerie(seriesInput, categoriesData), series = _a.series, categories = _a.categories;
    return {
        categories: categories,
        series: series,
    };
};
export var checkNoCategorySerie = function (series, categories) {
    if (series === void 0) { series = []; }
    if (categories === void 0) { categories = []; }
    var maxLength = 5;
    var seriesReplace = series.length > 0
        ? series
        : [
            {
                name: 'no data',
                data: Array(maxLength).fill(0),
            },
        ];
    var categoriesReplace = categories.length > 0 ? categories : Array(maxLength).fill('no Data ');
    return {
        series: seriesReplace,
        categories: categoriesReplace,
    };
};
